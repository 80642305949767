import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 953px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 714px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 723px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 734px;
  }
`
