import styled from 'styled-components'

type LinkProps = {
  reverse?: boolean;
}

export const Link = styled.a`
  width: 100%;
  height: 48px;
  border: 1px solid #ff7a00;
  border-radius: 8px;
  color: ${(props: LinkProps) => props.reverse ? '#ff7a00' : '#ffffff'};
  background:  ${(props: LinkProps) => props.reverse ? '#ffffff' : '#ff7a00'};
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: ${(props: LinkProps) => props.reverse ? '#ff7a00' : '#ffffff'};
    opacity: 0.9;
  }
`
