import React from 'react'
import Img from 'gatsby-image'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Link from '../../_componentes/_link/_index'

import pageQuery from '../../pageQuery'

import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

export const CardPlatinum = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 order-md-last'>
            <Img fluid={data.imageCardPlatinum.fluid} />
          </div>

          <div className='col-12 col-md-6 pr-md-0'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3'>Cartão Platinum com pontos e atendimento personalizado</h2>
            <h3 className='fs-16 lh-19 fs-lg-20 lh-lg-25 mb-4 text-grayscale--400 fw-lg-600'>Fazendo a portabilidade do seu salário a partir de R$6 mil líquido, você se torna um cliente Inter One!</h3>
            <div className='d-flex align-items-start mb-4'>
              <div className='d-flex mr-3'>
                <OrangeDsIcon icon='check-circle' size='MD' color='#ff7a00' />
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>Tenha um cartão Platinum com programa de pontos para trocar por vários benefícios.</p>
            </div>
            <div className='d-flex align-items-start'>
              <div className='d-flex mr-3'>
                <OrangeDsIcon icon='check-circle' size='MD' color='#ff7a00' />
              </div>
              <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>Conte com um advisor para te ajudar em qualquer assunto relacionado ao Super App como empréstimos, compras, seguros ou até para montar uma carteira de investimentos de acordo com o seu momento de vida.</p>
            </div>
            <Link
              href='https://inter.co/pra-voce/relacionamento/inter-one/'
              text='Saiba mais sobre Inter One'
              className='mt-5'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_04',
                  section_name: 'Cartão Platinum com mais cashback e atendimento personalizado',
                  element_action: 'click button',
                  element_name: 'Saiba mais sobre Inter One',
                  redirect_url: 'https://inter.co/pra-voce/relacionamento/inter-one/',
                 })
              }}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default CardPlatinum
