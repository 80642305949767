import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'
import AccountModal from 'src/components/UI/Forms/OpenAccountRightForm'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import pageContext from './pageContext.json'
import { Wrapper } from './style'

import Hero from './_sections/_hero/_index'
import Benefits from './_sections/_benefitis/_index'
import TheBest from './_sections/_the-best/_index'
import CardPlatinum from './_sections/_card-platinum/_index'
import BringYourSalary from './_sections/_bring-your-salaray/_index'
import Faq from './_sections/_faq/_index'

const SalaryPortability = () => {
  const [ isOpenAccount, setIsOpenAccount ] = useState(false)
  const domReady = useDomReady()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
    element_action: 'click button',
    element_name: 'Abrir conta',
  })

  const modalAccountOpen = domReady && (
    <Modal isModalOpen={isOpenAccount} setIsModalOpen={setIsOpenAccount} locationToRender={document.body}>
      <AccountModal closeModal={() => setIsOpenAccount(false)} dataLayer={dataLayer} />
    </Modal>
  )

  return (
    <Wrapper>
      {modalAccountOpen}
      <Layout pageContext={pageContext}>
        <Hero setIsOpenAccount={setIsOpenAccount} setDataLayer={setDataLayer} />
        <Benefits />
        <TheBest setIsOpenAccount={setIsOpenAccount} setDataLayer={setDataLayer} />
        <CardPlatinum />
        <BringYourSalary />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default SalaryPortability
