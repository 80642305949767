import React from 'react'
import Img from 'gatsby-image'

import theBastJSON from '../../assets/data/the-best.json'

import pageQuery from '../../pageQuery'

import { Section } from './style'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import Button from '../../_componentes/_button/_index'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type theBestProps = {
  icon: string;
  text: string;
}

type theBestModalProps = {
  setIsOpenAccount: Function;
  setDataLayer: Function;
}

const TheBest = ({ setIsOpenAccount, setDataLayer }: theBestModalProps) => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayer: IDataLayerParams = {
    section: 'dobra_03',
    section_name: 'O melhor do Inter pra você',
    element_action: 'click button',
    element_name: 'Abra sua Conta',
  }

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
            <Img fluid={data.imageTheBest.fluid} />
          </div>

          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-lx-40 lh-xl-50 text-grayscale--500 fw-600'>O melhor do Inter pra você</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>Quem tem conta no Inter tem soluções completas para todos os momentos.</p>

            {
              theBastJSON.map((item: theBestProps) => (
                <div className='d-flex align-items-center mb-4' key={item.text}>
                  <div className='d-flex mr-2'>
                    <OrangeDsIcon icon={item.icon} size='MD' color='#ff7a00' />
                  </div>
                  <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 mb-0'>{item.text}</p>
                </div>
              ))
            }

            <Button
              text='Abra sua Conta'
              onClick={() => {
                setIsOpenAccount(true)
                sendDatalayerEvent(dataLayer)
                setDataLayer(dataLayer)
              }}
              className='mt-4'
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default TheBest
