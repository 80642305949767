import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: #F5F6FA;
  min-height: 780px;

  @media (min-width: ${breakpoints.md}) { 
    min-height: 662px;
  }
  @media (min-width: ${breakpoints.lg}) { 
    min-height: 670px;
  }
  @media (min-width: ${breakpoints.xl}) { 
    min-height: 676px;
  }
`
