import React, { useState, useEffect, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

import bringYourSalaryJSON from '../../assets/data/bring-your-salaray.json'

import Link from '../../_componentes/_link/_index'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import Button from '../../_componentes/_button/_index'

import useWidth from 'src/hooks/window/useWidth'
import useDomReady from 'src/hooks/window/useDomReady'

import pageQuery from '../../pageQuery'

import { Section, Summary } from './style'

import QrCode from '../../assets/images/portabilitySalaryQrCode.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const WIDTH_MD = 768

type bringYourSalaryProps = {
  title: string;
  paragraph: string;
  active: boolean;
}

const BringYourSalary = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)
  const [ list, setList ] = useState(bringYourSalaryJSON)

  const width = useWidth(300)
  const data = pageQuery()
  const domReady = useDomReady()
  const [ sendDatalayerEvent ] = useDataLayer()

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o <a target="_blank" rel="noreferrer" href="https://intergo.app/f650449c" class="text-orange--extra">Super App</a> e faça a portabilidade agora!'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter.'
        subtitle='Traga seu salário para o Inter e aproveite todos os benefícios que só o nosso Super App pode oferecer.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const handleClick = (position: number) => {
    const newList = list.map((item: bringYourSalaryProps, index: number) => {
      if (position === index) {
        return {
          ...item,
          active: !item.active,
        }
      } else {
        return {
          ...item,
          active: false,
        }
      }
    })

    setList(newList)
  }

  useEffect(() => {
    const newList = list.map((item: bringYourSalaryProps) => {
      return item
    })

    setList(newList)
  }, [])

  return (
    <Section className='py-5 d-flex align-items-center'>
      {modalOpen}
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0'>
            <Img fluid={data.imageBringYourSalary.fluid} />
          </div>

          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-md-40 fs-xl-18 lh-xl-22 text-grayscale--500 fw-600'>Como trazer seu salário pro Inter</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400'>Em poucos passos você faz a sua portabilidade de salário para o Inter</p>
            <div className='my-4'>
              {
                list.map((item: bringYourSalaryProps, index: number) => (
                  <Summary key={item.title} active={item.active}>
                    <div>
                      <h3 className='fs-20 lh-25 fs-lg-24 lh-lg-30 mb-0' onClick={() => handleClick(index)}>{item.title}</h3>

                      {item.active && (<p className='fs-14 lh-17 fs-lg-18 lh-lg-22 mb-0'>{ item.paragraph }</p>)}
                    </div>
                  </Summary>
                ))
              }
            </div>
            {
              isMobile ? (
                <Link
                  href='https://intergo.app/f650449c'
                  text='Solicitar portabilidade'
                  className='mt-5'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      section_name: 'Como trazer seu salário pro Inter',
                      element_action: 'click button',
                      element_name: 'Solicitar portabilidade',
                      redirect_url: 'https://intergo.app/f650449c',
                     })
                  }}
                />
                ) : (
                  <Button
                    onClick={() => {
                      setIsOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_05',
                        section_name: 'Como trazer seu salário pro Inter',
                        element_action: 'click button',
                        element_name: 'Solicitar portabilidade',
                       })
                    }}
                    text='Solicitar portabilidade'
                    className='mt-5'
                  />
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
export default BringYourSalary
