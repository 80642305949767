import React, { ButtonHTMLAttributes } from 'react'

import { Link } from './style'

interface ILinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href: string;
  text: string;
  className?: string;
  reverse?: boolean;
  onClick: () => void;
}

const LinkButton = ({ href, text, className, reverse, onClick }: ILinkButtonProps) => {
  return (
    <Link href={href} title={text} className={className} reverse={reverse} onClick={onClick}>
      {text}
    </Link>
  )
}

export default LinkButton
