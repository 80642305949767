import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 756px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 451px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 500px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 700px;
  }

  .gatsby-image-wrapper {
    max-width: 456px;
    float: right;
    cursor: pointer;
  }
`
