import styled from 'styled-components'

type summaryProps = {
  active: boolean;
}

export const Section = styled.section`
  
`
export const Summary = styled.div`
  position: relative;
  display: flex;
  align-items: ${(props: summaryProps) => props.active ? 'start' : 'center'};

  & + div {
    margin-top: 48px;
  }

  h3 { 
    color: ${(props: summaryProps) => props.active ? '#161616' : '#ff7a00'};
    cursor: pointer;
  }

  p {
    margin-top: 10px;
  }

  &:before {
    content: '';
    display: block;
    width: 2px;
    min-width: 2px;
    height:  ${(props: summaryProps) => props.active ? '101px' : '31px'};
    background:  ${(props: summaryProps) => props.active ? '#161616' : '#FFDAB7'};
    border-radius: 0px 16px 16px 0px;
    margin-right: 16px;
  }
`
