import styled from 'styled-components'

// Types
type GlobalButtonProps = {
  reverse?: boolean;
}

export const GlobalButton = styled.button`
  width: 100%;
  height: 48px;
  border: 1px solid #ff7a00;
  border-radius: 8px;
  color: ${(props: GlobalButtonProps) => props.reverse ? '#ff7a00' : '#ffffff'};
  background:  ${(props: GlobalButtonProps) => props.reverse ? '#ffffff' : '#ff7a00'};
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;

  &:focus {
    outline: none;
  }
`
