import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  background: #ff7a00;
  min-height: 706px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 875px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 520px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 510px;
  }
`
export const Card = styled.div`
  width: 100%;
  min-height: 149px;
  background: #F5F6FA;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  justify-content: center;
  
  @media (min-width: ${breakpoints.md}) {
    min-height: 188px;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 247px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 227px;
  }
`
