import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      imageHero: imageSharp(fluid: {originalName: { regex: "/salaryPortabilityHero/" }}) {
        fluid(maxWidth: 372, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imageTheBest: imageSharp(fluid: {originalName: { regex: "/salaryPortabilityTheBest/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imageCardPlatinum: imageSharp(fluid: {originalName: { regex: "/salaryPortabilityCardPlatinum/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      imageBringYourSalary: imageSharp(fluid: {originalName: { regex: "/SalaryPortabilityBringYourSalary/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
