import React from 'react'

// Styles
import { GlobalButton } from './style'

// Types
type ButtonProps = {
  reverse?: boolean;
  text: string;
  onClick: Function;
  className?: string;
}

const Button = ({ reverse, text, onClick, className }: ButtonProps) => {
  return (
    <GlobalButton reverse={reverse} onClick={() => onClick()} className={className}>
      {text}
    </GlobalButton>
  )
}

export default Button
