import React from 'react'

// Data
import benefitsJSON from '../../assets/data/benefits.json'

// Components
import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

// styles
import { Section, Card } from './style'

type benefitsJSONProps = {
  icon: string;
  title: string;
  paragraph: string;
}

const Benefits = () => {
  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-md-center text-white mb-4'>Benefícios de fazer portabilidade para o Inter</h2>

            <div className='row d-flex align-items-center'>
              {
                  benefitsJSON.map((item: benefitsJSONProps) => (
                    <div className='col-12 col-lg-4 mb-4 mb-lg-0' key={item.title}>
                      <Card>
                        <div className='d-flex justify-content-md-center mb-md-4 mr-4 mr-md-0'>
                          <OrangeDsIcon icon={item.icon} size='MD' color='#ff7a00' />
                        </div>
                        <div>
                          <h3 className='fs-16 lh-20 text-grayscale--500 fw-600 mb-2'>{item.title}</h3>
                          <p className='fs-14 lh-17 fs-md-16 lh-md-19 text-grayscale--400 mb-0'>{item.paragraph}</p>
                        </div>
                      </Card>
                    </div>
                  ))
                }
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Benefits
