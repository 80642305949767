import React, { useState, useLayoutEffect } from 'react'
import Img from 'gatsby-image'

import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import pageQuery from '../../pageQuery'

import Button from '../../_componentes/_button/_index'
import Link from '../../_componentes/_link/_index'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import OpenVideo from 'src/components/OpenVideo'

import { Section } from './style'

import QrCode from '../../assets/images/portabilitySalaryQrCode.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const WIDTH_MD = 768

type HeroProps = {
  setIsOpenAccount: Function;
  setDataLayer: Function;
}

const Hero = ({ setIsOpenAccount, setDataLayer }: HeroProps) => {
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isMobile, setIsMobile ] = useState(true)
  const data = pageQuery()
  const domReady = useDomReady()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  const modalOpen = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title='Acesse o <a target="_blank" rel="noreferrer" href="https://intergo.app/f650449c" class="text-orange--extra">Super App</a> e faça a portabilidade agora!'
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para a loja de aplicativos. Aí é só baixar o app do Inter.'
        subtitle='Traga seu salário para o Inter e aproveite todos os benefícios que só o nosso Super App pode oferecer.'
        qrCode={QrCode}
      />
    </Modal>
  )

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
    element_action: 'click button',
    element_name: 'Abrir conta',
  }

  return (
    <>
      {modalOpen}
      <Section className='pb-5 pt-lg-4 d-flex position-relative align-items-center'>
        <div className='container'>
          <div className='row d-flex align-items-center justify-content-between'>
            <div className='col-12 col-md-6 col-lg-5 mb-5 mb-md-0 order-md-last'>
              <OpenVideo
                link='https://www.youtube.com/embed/bkgh09XXtS0'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                    element_action: 'click video',
                    element_name: 'Saiba mais sobre Portabilidade de Salário',
                    redirect_url: 'https://www.youtube.com/embed/bkgh09XXtS0',
                   })
                }}
              >
                <Img
                  fluid={data.imageHero.fluid} loading='eager' alt='Imagem de player de vídeo Inter'
                />
              </OpenVideo>
            </div>
            <div className='col-12 col-md-6'>
              <h1 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>
                Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita
              </h1>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500'>
                Traga seu salário para o Inter e aproveite todos os benefícios que só o nosso Super App pode oferecer.
              </p>

              {
                isMobile ? (
                  <Link
                    href='https://intergo.app/f650449c'
                    className='mb-3 mt-4'
                    text='Solicitar portabilidade'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                        element_action: 'click button',
                        element_name: 'Solicitar portabilidade',
                        redirect_url: 'https://intergo.app/f650449c',
                       })
                    }}
                  />
                  ) : (
                    <Button
                      onClick={() => {
                        setIsOpen(true)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          section_name: 'Portabilidade de Salário no Inter: a liberdade de uma conta digital gratuita',
                          element_action: 'click button',
                          element_name: 'Solicitar portabilidade',
                        })
                      }}
                      text='Solicitar portabilidade'
                      className='mb-3 mt-4'
                    />
                )
              }
              <Button
                onClick={() => {
                  setIsOpenAccount(true)
                  sendDatalayerEvent(dataLayer)
                  setDataLayer(dataLayer)
                }}
                text='Abrir conta'
                reverse={true}
              />
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Hero
